import request from "@/utils/request.js";
import { json2FormData } from "@/utils/string";
import { parseTime } from "@/utils/index";
import Cookies from "js-cookie";

export function getDicts(dictType) {
  return request({
    url: '/order/v1/orderRecharge/type/' + dictType,
    method: 'get'
  })
}

export function setUserIccid(iccid,openId) {
  const data = {};
  data.iccid = iccid;
  data.openId = openId;
  console.log(data)
  return request({
    url: "/order/wx/v1/user/updateIccid",
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}
export function getCard(iccid, officialId) {
  const data = {};
  data.iccid = iccid;
  data.officialId = officialId;
  if(!iccid||!officialId){
    if(localStorage.getItem('admin') == 1){alert('getCard传空')}
  }
  return request({
    url: "/order/v1/wx/combo/getCard",
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}

export function getCombos(comboValidDateValue) {
  const data = {};
  let appid = localStorage.getItem('resFlog1')
  let iccid = Cookies.get('card-id')||localStorage.getItem('user').iccid
  data.officialId = appid;
  data.iccid = iccid;
  data.comboValidDateValue = comboValidDateValue;
  return request({
    url: "/order/v1/wx/combo/getCombo",
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function removeOrder(orderId) {
  return request({
    url: "/order/wx/v1/order/deletePayFailOrder?orderId="+orderId,
    method: "get",
  });
}
export function createOrder(iccid, groupId, comboId, payType, officialId, openid) {
  const data = {};
  data.groupId = groupId;
  data.comboId = comboId;
  data.iccid = iccid;
  data.payType = payType;
  data.officialId = officialId;
  data.openid = openid;
  return request({
    url: "/order/wx/v1/order/createOrder",
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function selectCardUsed(iccid) {
  const data = {};
  // data.cycleYm = cycleYm;
  // data.iccid = iccid;
  
  data.effectiveDate = parseTime(new Date(), "{y}-{m}-{d}");
  data.iccid = iccid;
  return request({
    url: "/order/v1/wx/combo/selectCardUsed",
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
export function selectCardUsing(iccid) {
  const data = {};
  // data.effectiveDate = parseTime(new Date(), "{y}-{m}-{d}");
  data.iccid = iccid;
  return request({
    url: "/order/v1/wx/combo/selectCardUsing",
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function getOrderId(orderId) {
  const data = {};
  data.orderId = orderId;
  return request({
    url: "/order/v1/orderRecharge/getOrderId",
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function selectOrder(iccid,orderStat,pageNum,pageSize) {
  const data = {};
  data.iccid = iccid;
  data.orderStat = orderStat;
  data.pageNum = pageNum;
  data.pageSize = pageSize;
  return request({
    url: "/order/v1/orderRecharge/selectOrder",
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function selectOrderCombo(iccid,orderStat,pageNum,pageSize) {
  const data = {};
  data.iccid = iccid;
  data.orderStat = orderStat;
  data.pageNum = pageNum;
  data.pageSize = pageSize;
  return request({
    url: "/order/v1/orderRecharge/selectOrderCombo",
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data",
      "Cache-Control":'no-cache'
    }
  });
}

export function updateCardWhitenum(data) {
  return request({
    url: "/order/v1/wx/combo/updateCardWhitenum",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json"
    }
  });
}
export function insertWhite(iccid,msisdn,whiteNumList) {
	for(let i = 0;i<whiteNumList.length;i++){
		whiteNumList[i].iccid=iccid;
		whiteNumList[i].msisdn=msisdn;
	}
  console.log('testttttttttttttttt',whiteNumList)
  return request({
    url: "/order/v1/wx/combo/insertWhite",
    method: "post",
    data: whiteNumList,
    headers: {
      "Content-Type": "application/json"
    }
  });
}
export function selectList(iccid) {
  const data = {};
  data.iccid =  iccid;
  return request({
    url: "/order/v1/wx/combo/selectList",
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data"
    },
  });
}
export function selectOrderList(iccid) {
  const data = {};
  data.iccid =  iccid;
  return request({
    url: "/order/wx/v1/order/selectOrderList",
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data"
    },
  });
}
export function selectBalanceRecord(iccid) {
  const data = {};
  data.iccid = iccid;
  return request({
    url: "/order/v1/orderRecharge/selectBalanceRecord",
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
export function insertRepair(data,fileList) {
	let content = fileList;
	let formData = new FormData();
	if(Array.isArray(content)) {
		content.forEach(item => {
			formData.append("file",item.file);
		});
	}else{
		formData.append("file", content.file);
	}
	formData.append("contact",data.contact);
	formData.append("phone",data.phone);
	formData.append("content",data.content);
	console.log('file',formData.getAll("file"));
  return request({
    url: "/order/v1/wx/combo/insertRepair",
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
//余额记录 收入-支出
export function selectRecord(iccid) {
  return request({
    url: "/order/v1/orderRecharge/selectRecord/"+iccid,
    method: "get",
  });
}
//实名
export function realName(iccid) {
  return request({
    url: "/order/v1/orderRecharge/realName/"+iccid,
    method: "get",
  });
}