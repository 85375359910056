/*
 * @Author: your name
 * @Date: 2021-09-13 15:13:31
 * @LastEditTime: 2021-09-15 13:56:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \wxd_officials\src\store\modules\card.js
 */
import { getCard } from "@/api/card";
import { getCardId, setCardId, setCorp, getCorp, getMsisdn, setMsisdn } from "@/utils/auth";

const card = {
  state: {
    officialId: "",
    officialIds: "",
    iccId: getCardId(),
    msisdn: getMsisdn(),
    corp: getCorp(),
    // 实名状态
    realNameAuth: null,
    // 卡类型
    cardType: null,
    // 是否跳转过来打开实名
    isRealName: false,
  },

  mutations: {
    SET_OFFICIAL_ID: (state, officialId) => {
      state.officialId = officialId;
    },
    SET_OFFICIAL_IDS: (state, officialIds) => {
      state.officialIds = officialIds?officialIds:JSON.parse(localStorage.getItem('officialId'))
    //   // 每次刷新都会把公众号id刷掉，保留第一次获取的到会话存储
      localStorage.setItem('officialId',JSON.stringify(officialIds))
    },
    SET_REAL_NAME_AUTH: (state, realNameAuth) => {
      state.realNameAuth = realNameAuth;
    },
    SET_CARD_TYPE: (state, cardType) => {
      state.cardType = cardType;
    },
    SET_IS_REAL_NAME: (state, isRealName) => {
      state.isRealName = isRealName;
    },
    SET_ICC_ID: (state, iccId) => {
      state.iccId = iccId;
    },
    SET_MSISDN: (state, msisdn) => {
      state.msisdn = msisdn;
    },
    SET_CORP: (state, corp) => {
      state.corp = corp;
    }
  },

  actions: {
    getCardInfo({ commit }, params) {
      return new Promise((resolve, reject) => {
        getCard(params.iccid, params.officialId)
          .then(res => {
            
            // let wid = params.officialId?params.officialId:params.officialId[0]
            let wid = params.officialId
            commit("SET_ICC_ID", res.iccid);
            commit("SET_MSISDN", res.msisdn);
            commit("SET_CORP", res.corp);
            commit("SET_OFFICIAL_IDS", wid);
            setCorp(res.corp);
            setCardId(res.iccid);
            setMsisdn(res.msisdn);
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};

export default card;
