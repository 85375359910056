import axios from "axios";
import { Notify, Dialog } from "vant";
import store from "@/store";
import { getToken } from "@/utils/auth";
import errorCode from "@/utils/errorCode";
import Cookies from "js-cookie";
// import { getWxAuth, getUrlParam } from "@/api/user";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 120000
});
let resFlog1 = localStorage.getItem("resFlog1")
// request拦截器
service.interceptors.request.use(
  config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    let href = window.location.href
    console.log('路径',href)
    // config.headers['Tenant-Id'] = hostname.includes('tenant-vx.wxd')?'wxd001':hostname.includes('tenant-vx2.wxd')?'wxd002':''
    // config.headers['Tenant-Id'] = localStorage.getItem('isKey')=='key=1'?'wxd001':localStorage.getItem('isKey')=='key=2'?'wxd002':''
    // if(href.includes('tenant-vx')){
    //   config.headers['Tenant-Id'] = href.includes('key=1')?'wxd001':href.includes('key=2')?'wxd002':'0'
    // }
    // config.headers['Tenant-Id'] = 'wxd001
    if (getToken() && !isToken) {
      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  error => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  res => {
    // 未设置状态码则默认成功状态
    console.info("result:", res);
    console.info("pc:", res.config.url);
    const code = res.data.respCode || "200";
    console.info(code);
    // 获取错误信息
    const message = errorCode[code] || res.data.respDesc || errorCode["default"];
    // if(localStorage.getItem('adminTest') == 1){
    //   localStorage.setItem('adminTest',0)
    //   Dialog.confirm({
    //     title: "系统提示",
    //     message: "登录状态已过期，您可以继续留在该页面，或者重新登录"
    //   }).then(() => {
    //     store.dispatch("removeUser").then(() => {
    //       location.reload(); // 为了重新实例化vue-router对象 避免bug
    //     });

    //   });
    // }
    let token =Cookies.get('user-token')
    if (code === "401") {
        // alert('401系统错误,请截图联系后台')
        Dialog.confirm({
          title: "系统提示",
          message: "登录状态已过期，您可以继续留在该页面，或者重新登录"
        }).then(() => {
          store.dispatch("removeUser").then(() => {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
    }else
    // if ((code === "08" && !token) || code === '01') {
    if (code === "08" && !token ) {
          // alert('08系统错误,请截图联系后台')
          // Notify({
          //   message: code+'参数出错，重新授权中...',
          //   type: "warning"
          // });
          setTimeout(()=>{
            store.dispatch("removeUser").then(() => {
              // this.$router.push("http://card.wxd-iot.com/"+resFlog1)
              location.reload(); // 为了重新实例化vue-router对象 避免bug
              // location.href = "http://card.wxd-iot.com/"+resFlog1
            });
          },500)
    }
    // if (code === "13")基本与code有关
    else if (code === "14") {
        // alert('14系统错误,请截图联系后台')
        Dialog.confirm({
          title: "系统提示",
          message: "检测到iccid更换，确认将继续"
          // message: "token过期"
        }).then(() => {
          store.dispatch("removeUser").then(() => {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
    }else
     if (code === "500") {
      // alert('500系统错误,请截图联系后台')
      Notify({
        message: code + message,
        // message:  message,
        type: "danger"
      });
      return Promise.reject(new Error(message));
    }
    else if (code === "09") {
      // alert('09系统错误,请截图联系后台')
      Notify({
        message: '长时间未操作，请稍后重试...',
        type: "danger"
      });

    } 
    else if (code !== "200") {
      // alert(code+'系统错误,请截图联系后台')
      console.log('其他不等于200')
      Notify({
        message: code + message,
        type: "danger"
      });
      return Promise.reject("error");
    } else if(code == "200"){       
        if((res.config.url).includes('agentCallback')||(res.config.url).includes('agentAuth')){//pcFlag
          return res
        }else{
          console.log('通过')
          return res.data.respData;
        }
    }
    // Notify({
    //   message: '长时间未操作，请稍后重试...',
    //   type: "danger"
    // const officialId = store.getters.officialId;
    // const targetUrl = window.location.href;
    // getWxAuth(officialId, targetUrl).then(res => {
    //   // localStorage.setItem('resFlog',to.params.officialId)
    //   console.log('调起授权。参数：',officialId,targetUrl,'响应：',res)
    //   window.location.href = res;
    // });

  },
  error => {
    console.log("err====>" + error);
    Notify({
      message: error.message,
      type: "danger",
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;
