import { createStore } from "vuex";
import getters from "./getters";
import user from "./modules/user";
import card from "./modules/card";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    card
  },
  getters
});
