/*
 * @Author: your name
 * @Date: 2021-09-13 15:13:31
 * @LastEditTime: 2021-09-15 18:30:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \wxd_officials\src\api\user.js
 */
import request from "@/utils/request.js";
import { json2FormData, str2Json } from "@/utils/string";
import { Dialog,Notify,Toast } from "vant";

// export function getWxAuth(officialId, targetUrl) {
//   const data = {};
//   data.id = officialId || "";
//   data.destUrl = targetUrl;
//   url: "/order/wx/v1/user/redirectUrl",
// }
/**
 * 发起授权用户信息界面
 * @param agentName 公众号名
 * @param targetUrl 跳转后地址
 */
export function getWxAuth(officialId, targetUrl) {
  const data = {};
  data.id = officialId || "";
  data.destUrl = targetUrl;
  // let tenantId = 'wxd-00'+key
  return request({
    url: "/order/wx/v1/user/redirectUrl",
    method: "post",
    data: json2FormData(data),
    headers: {
      // "Tenant-Id":'wxd001',
      "Content-Type": "multipart/form-data"
    }
  });
}

/**
 * 获取用户信息
 * @param agentName 公众号名
 * @param code 授权后的Code
 */
export function getWxUserInfo(officialId, code) {
  // console.log('登录传给后端的appid(officialId和code)',officialId,code)
  const data = {};
  // data.officialId = officialId || "";
  data.code = code || "";
  console.log('获取用户信息',code)
  return request({
    url: "/order/wx/v1/user/login/" + officialId,
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

/**
 * pcccccccccccccc
 * @param code
 * @param userName
 */
export function agentCallbackPc(code,userName) {
  const data = {userName,code};
  console.log('参数',userName)
  return request({
    url: "/order/wx/v1/user/agentCallback",
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
/**
 * pcccccccccccccc
 * @param url 回调路径
 */
export function agentAuthPc(destUrl) {
  // const data = {destUrl};
  // console.log('获取回调路径',destUrl)
  return request({
    url: "/order/wx/v1/user/agentAuth?destUrl="+destUrl,
    method: "get",
  });
}

export function getJsApiSign(url, jsApiList,officialId,openid) {
  const data = {};
  data.url = url;
  data.jsApiList = str2Json(jsApiList);
  data.officialId = officialId;
  data.openId = openid;
  return request({
    url: "/order/wx/v1/user/createJsApiSign",
    method: "post",
    data: json2FormData(data),
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function getUrlParam(name) {
  // const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  // const r = window.location.search.substr(1).match(reg);
  // --r[2]
  let ov = ''
  let ov0 = ''
  const reg2 = new RegExp("(^|&)" + name + "=([^&]*)(&|$)","g");
  const r11 = window.location.search.substr(1).match(reg2);
  let last = r11[r11.length-1]
    // 解决登陆页面刷新会重新授权，导致链接拼接多个code问题，此处做精确拿取最新的拿一个
    const targetUrl = window.location.href;
    console.log(window.location.href)
    // if多参，路径是key，pc提现
  if(r11.length>1 || targetUrl.includes('pcFlag') || targetUrl.includes('wx772beb18b653aaaf?key=1')){
    ov0 = last.substring(name.length+1,last.length-1)
    ov = last.substring(6,last.length-1)
    console.log(r11,last,ov,ov0)
  }else{
    // ov0 = last.substring(name.length,last.length-1)
    ov = last.substring(5,last.length-1)
    console.log(r11,ov,ov0)
  }
    // ov = r11.length>1 ? last.substring(6,last.length-1) : last.substring(5,last.length-1)
  // --ov
  if (r11 != null) {
    // 特殊情况 pc这里不带appid的路径，userName前？暂时没解析
    return unescape(name == 'userName'?ov0:ov)
  };
  return null;
}
