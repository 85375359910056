/*
 * @Author: your name
 * @Date: 2021-09-13 15:13:31
 * @LastEditTime: 2021-09-15 13:55:26
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \wxd_officials\src\permission.js
 */ 
import router from "./router";
import store from "@/store";
import { getWxAuth, getUrlParam, getWxUserInfo} from "@/api/user";
import { setCardId } from "@/utils/auth";
// import Cookies from "js-cookie";
// import { Dialog,Notify,Toast } from "vant";

router.beforeEach((to, from, next) => {
  if(to.name != 'pcFlag'){
        let toPath = to.path
        let nn = 62
        let update = 'testNum'+nn
        // for(let i =0;i<nn;i++){
        //   // localStorage.removeItem('testNum'+i)
        // }
        //设置一个全平台只执行一次的num，重新授权 实现系统更新，以解决更新代码前的bug
        let testNum = localStorage.getItem(update)
        let resFlog =  null
        resFlog =  to.params.officialId
        store.commit("SET_OFFICIAL_ID", resFlog);
        const loginStatus = Number(store.getters.loginStatus);
        const officialId = resFlog||store.getters.officialId;
        // if( !loginStatus && (testNum == 0 || !testNum ) && !from.name && toPath.length == 19){
        //   localStorage.clear()
        //   localStorage.clear()
        //   Cookies.clear()
        // }
        let oiccid = localStorage.getItem('cardId')
        let realNameFlag = localStorage.getItem('realNameFlag')
        // let loginRef = sessionStorage.getItem('loginRef')
        // 进入登录页面(不带路由19长度)
        // 防止首页返回到登录导致刷新，只有直接访问公众号链接有效
        // 防止刚登录时刷新导致再次授权，code过期导致13api错误
        if(toPath.length == 19 && !from.name 
          // && loginRef != 1
          ){
          localStorage.clear()
        }
        localStorage.setItem('cardId',oiccid)
        localStorage.setItem('realNameFlag',realNameFlag)
        /* 路由发生变化修改页面title */
        document.title = to.meta.title || document.title;
        // const code = getUrlParam("code");
        // Notify({ type: 'success', message:  '登录传给后端的code'+code});
        console.log('to, from',to, from)

        if (to.params.iccid !== undefined && to.params.iccid !== "") {
          store.commit("SET_ICC_ID", to.params.iccid);
          setCardId(to.params.iccid);
        }else{
          console.log('未带iccid')
        }

        // let path = to
        // .fullPath.slice(1) // 去除'/'
        if (!localStorage.getItem("initLink")) {
          // 解决ios微信下，分享签名不成功的问题,将第一次的进入的url缓存起来。 
          localStorage.setItem("initLink", document.URL);
        }
        
        let url = '';
        if (window.__wxjs_is_wkwebview) {
          // ios
          url = localStorage.getItem("initLink");
        } else {
          // 安卓 process.env.BASE_URL 自己定义各个环境下域名变量
          url = location.origin + to.fullPath;
        }
        if(!to.path.includes(resFlog)){//＋如果路径的公众号变化，也更新initLink
          localStorage.setItem("initLink", document.URL);
          url = location.origin + to.fullPath;
        }
        store.commit("SET_INIT_LINK", url);
        // let resFlog = to.params
        // let locOfficialId = JSON.parse(localStorage.getItem('locOfficialId'))

        //!路径offid变化重新授权======登录状态过时或未授权登录=======进入时强制重新授权一次----不是从别的路径返回到登陆界面----19路由长度是登陆界面的唯一性
        console.log('55555555555555555555555555555',!to.path.includes(resFlog),!loginStatus,testNum == 0 || !testNum , !from.name && toPath.length == 19)
        if(!to.path.includes(resFlog)||!loginStatus||((testNum == 0 || !testNum ) && !from.name && toPath.length == 19 )){//||(!testNum&&!from.name)
        // if(!to.path.includes(resFlog)||!loginStatus||( !from.name && toPath.length == 19 )){//||(!testNum&&!from.name)
                // #f00登陆页面刷新
                const targetUrl = window.location.href;
                store.dispatch("setLoginStatus", 1);
                // if(!to.path.includes(resFlog)){
                //   console.log('======================================5-0')
                //   alert('-----')
                //   localStorage.setItem(update,1)//------------
                //   localStorage.setItem('resFlog1',to.params.officialId)
                //     window.location.href = 'http://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
                //     officialId +
                //     '&redirect_uri=' +
                //     targetUrl +
                //     '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
                //    'http://open.weixin.qq.com/connect/oauth2/authorize?appid=wx42d6dd29d1079854&redirect_uri=http://card.wxd-iot.com/wx42d6dd29d1079854&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
                // }else{
                // 获取微信授权页面，并且重定向回来targetUrl
                
                  // let key = ''
                  // let hostname = window.location.hostname
                  // console.log(hostname,hostname.includes('tenant-vx'))
                  // if(hostname.includes('tenant-vx')){
                  //   key = to.query.key
                  // }
                  console.log(1111111111111111111)
                  getWxAuth(officialId, targetUrl).then(res => {
                    localStorage.setItem(update,localStorage.getItem('admin') == 1 ?2:1)
                    if(to.params.officialId){localStorage.setItem('resFlog1',to.params.officialId)}
                      window.location.href = res;
                  }).catch((res)=>{
                    console.log(res)

                  });
                  next();
                // }
        }
        // else if( loginRef == 1){
        //   location.reload()
        // }
        else if (loginStatus === 1) {
          const code = getUrlParam("code");
          localStorage.setItem('codee',code)//测试
          // 拒绝授权登录，返回页面，需重新发起授权
          if (code == null) {
            store.dispatch("setLoginStatus", 0);
          } else {   
            // 写入用户数据
            store.dispatch("getWxUserInfo", {
              officialId: officialId,
              code: code
            });
            // getWxUserInfo(officialId,code).then((res)=>{
            //   console.log(res)
            // })
            store.dispatch("setLoginStatus", 2);
          }
          next();
        }else {
          store.dispatch("getLocalUserInfo");
          next();
        }
        
  }else{
    next()
  }
});
