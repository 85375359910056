/*
 * @Author: your name
 * @Date: 2021-09-13 15:13:31
 * @LastEditTime: 2021-09-15 13:56:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \wxd_officials\src\store\modules\user.js
 */
import {
  getToken,
  setToken,
  removeToken,
  setLoginStatus,
  removeLoginStatus,
  getLoginStatus,
  getUserInfo,
  removeUserInfo,
  setUserInfo
} from "@/utils/auth";
import { getWxUserInfo } from "@/api/user";
import { str2Json } from "@/utils/string";

const user = {
  state: {
    token: getToken(),
    name: "",
    avatar: "",
    userInfo: {},
    openId: "",
    loginStatus: getLoginStatus(),
    initLink: "",
    // officialId
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
      localStorage.setItem('user-info',JSON.stringify(userInfo))

    },
    SET_OPEN_ID: (state, openId) => {
      state.openId = openId;
    },
    SET_LOGIN_STATUS: (state, loginStatus) => {
      state.loginStatus = loginStatus;
    },
    SET_INIT_LINK: (state, initLink) => {
      state.initLink = initLink;
    },
    // SET_OFFICIAL_ID: (state, officialId) => {
    //   state.officialId = officialId;
    // },
  },
  actions: {
    getWxUserInfo({ commit }, params) {
      console.log('1111111111111111111111111111111111111111')
      return new Promise((resolve, reject) => {
        console.log('2222222222222222222222222222222222',params)
        getWxUserInfo(params.officialId, params.code).then(res => {
            console.log('用户信息',res)
            // localStorage
            commit("SET_NAME", res.nickname);
            commit("SET_AVATAR", res.headimgurl);
            commit("SET_OPEN_ID", res.openid);
            commit("SET_TOKEN", setToken(res.userToken));
            commit("SET_USER_INFO", setUserInfo(str2Json(res)));
            resolve();
            console.log('33333333333333333333333333333333333')
          })
          .catch(error => {
            console.log('44444444444444444444444444444444')
            reject(error);
          });
      });
    },
    setLoginStatus({ commit }, query) {
      // 设置不同的登录状态
      commit("SET_LOGIN_STATUS", setLoginStatus(query));
    },
    getLocalUserInfo({ commit }) {
      const userinfo = getUserInfo();
      // commit("SET_NAME", userinfo.nickname);
      // commit("SET_AVATAR", userinfo.headimgurl);
      // commit("SET_OPEN_ID", userinfo.openid);
      console.log(userinfo)
      // commit("SET_OFFICIAL_ID", userinfo.officialId);
    },
    removeUser() {
      // removeToken();
      // removeUserInfo();
      removeLoginStatus();
    }
  }
};

export default user;
