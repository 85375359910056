<!--
 * @Author: your name
 * @Date: 2021-09-13 15:13:31
 * @LastEditTime: 2021-09-14 15:40:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \wxd_officials\src\App.vue
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name:  'App',
  created() {
    // localStorage.clear()
    // if(!this.$router.path){
    //   this.$router.replace("/combo/wx996229f81e6a88f8")
    //   // this.$router.push("/combo/wx996229f81e6a88f8")
    // }
    // let url= window.location.href;
    // let index = url.substring(url.lastIndexOf('/wx') + 1);
    // console.log(url,index)
  //   this.$router.push("/home");
  //   let path =
  //     "http://192.168.1.25:3005/order/v1/wx/combo/getCard?iccid=8986112121708928597&officialId=home";
  //     // "ws://wxd-iot.tpddns.cn:3004/v1/wx/combo/getCard?iccid=8986112121708928597&officialId=home";
  //   let socket = "";
  //   socket = new WebSocket(path);
  //   console.log(socket, "12333333");
  }
};
</script>
<style>
html,
body,
#app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
</style>
