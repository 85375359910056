import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
// 剪切板
import VueClipboard from 'vue-clipboard2'
import "vant/lib/index.css";
import "@/permission"; // permission control
// createApp.config.productionTip = false;//屏蔽提示信息

// ===================================================

// Vue.prototype.getOfficialId =function(){
//   let wid = localStorage.getItem('officialId')
//   return wid
// }

// Vue.prototype.$deBug =function(e,f){
//   if(!e){
//     alert(f+'系统错误,请截图联系后台')
//   }else{
//     return e
//   }
// }
// if(localStorage.getItem('admin') != 1){
//   console.log =function(){}//把打印语句都注销
//   console.info =function(){}//把打印语句都注销
// }
createApp(App)
  .use(store)
  .use(router)
  .use(Vant)
  .use(VueClipboard)
  .mount("#app");
