import Cookies from "js-cookie";
const TokenKey = "user-token";
const LoginStatusKey = "login-status"; // 登录态 0未授权未登录 1授权未登录 2 登陆成功
const UserInfoKey = "user-info"; // 用户信息 {} {...}
const CardIdKey = "card-id"; // 存放ICCID
const Corp = "corp";// 存放CORP
const Msisdn = "msisdn";// 存放MSISDN

export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  Cookies.set(TokenKey, token);
  return token;
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getLoginStatus() {
  return Cookies.get(LoginStatusKey) || 0;
}
export function setLoginStatus(status) {
  Cookies.set(LoginStatusKey, status, { expires: 7 });
  return status;
}
export function removeLoginStatus() {
  Cookies.remove(LoginStatusKey);
  return "";
}

// 获取用户信息
export function getUserInfo() {
  return JSON.parse(localStorage.getItem(UserInfoKey, {}));
}
// 保存用户信息
export function setUserInfo(userInfo) {
  localStorage.setItem(UserInfoKey, userInfo);
  return JSON.parse(userInfo);
}
// 删除用户信息
export function removeUserInfo() {
  localStorage.removeItem(UserInfoKey);
  return {};
}

export function getCardId() {
  return Cookies.get(CardIdKey);
}
export function setCardId(cardId) {
  Cookies.set(CardIdKey, cardId);
}
export function removeCardId() {
  return Cookies.remove(CardIdKey);
}


export function getCorp() {
  return Cookies.get(Corp);
}
export function setCorp(corp) {
  Cookies.set(Corp, corp);
}

export function getMsisdn() {
  return Cookies.get(Msisdn);
}
export function setMsisdn(msisdn) {
  Cookies.set(Msisdn, msisdn);
}