/*
 * @Author: your name
 * @Date: 2021-09-13 15:13:31
 * @LastEditTime: 2021-09-15 11:16:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \wxd_officials\src\store\getters.js
 */
const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  userInfo: state => state.user.userInfo,
  openId: state => state.user.openId,
  loginStatus: state => state.user.loginStatus,
  name: state => state.user.name,
  initLink: state => state.user.initLink,
  // officialId0: state => state.user.officialId,
  iccId: state => state.card.iccId,
  officialId: state => state.card.officialId,
  officialIds: state => state.card.officialIds,
  msisdn: state => state.card.msisdn,
  corp: state => state.card.corp,
  channelId: state => state.card.channelId,
  realNameAuth: state => state.card.realNameAuth,
  cardType: state => state.card.cardType,
  isRealName: state => state.card.isRealName,
};
export default getters;
