import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:officialId",
    name: "index",
    meta: {
      title: "首页"
    },
    component: () => import("@/views/home/index.vue")
  },
  {
    path: "/pcFlag",
    name: "pcFlag",
    meta: {
      title: "微信授权登录"
    },
    component: () => import("@/views/home/pcFlag.vue")
  },
  {
    path: "/combo/:officialId/:iccid?",
    name: "combo",
    meta: {
      title: "套餐充值"
    },
    component: () => import("@/views/combos/combo.vue")
  },
  {
    path: "/balance/:officialId/:iccid?",
    name: "balance",
    meta: {
      title: "余额充值"
    },
    component: () => import("@/views/combos/balance.vue")
  },
  {
    path: "/info/:officialId",
    name: "info",
    meta: {
      title: "我的信息"
    },
    component: () => import("@/views/my/info.vue")
  },
  {
    path: "/recharge/:officialId",
    name: "recharge",
    meta: {
      title: "我的订单"
    },
    component: () => import("@/views/my/order.vue")
  },
  {
    path: "/used/:officialId",
    name: "used",
    meta: {
      title: "我的用量"
    },
    component: () => import("@/views/my/used.vue")
  },
  {
    path: "/whitenum/:officialId",
    name: "whitenum",
    meta: {
      title: "白名单配置"
    },
    component: () => import("@/views/my/whitenum.vue")
  },
  {
    path: "/order/:officialId",
    name: "order",
    meta: {
      title: "充值记录"
    },
    component: () => import("@/views/my/order.vue")
  },
  {
    path: "/balanceRecord/:officialId",
    name: "balanceRecord",
    meta: {
      title: "余额记录"
    },
    component: () => import("@/views/my/balanceRecord.vue")
  },
  {
    path: "/repair/:officialId",
    name: "repair",
    meta: {
      title: "留言板"
    },
    component: () => import("@/views/my/repair.vue")
  },
  {
    path: "/flowAdd/:officialId",
    name: "flowAdd",
    meta: {
      title: "充流量"
    },
    component: () => import("@/views/my/flowAdd.vue")
  },
  {
    path: "/voiceAdd/:officialId",
    name: "voiceAdd",
    meta: {
      title: "充语音"
    },
    component: () => import("@/views/my/voiceAdd.vue")
  },
  // {
  //   path: "/admin/:officialId/:iccid?",
  //   name: "admin",
  //   meta: {
  //     title: "余额充值"
  //   },
  //   component: () => import("@/views/combos/balance.vue")
  // },
];

const router = createRouter({
  mode:'hash',
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
