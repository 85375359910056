export function formData2Json(formData) {
  const jsonData = {};
  formData.forEach((value, key) => (jsonData[key] = value));
  return jsonData;
}

export function json2FormData(jsonStr) {
  const formData = new FormData();
  Object.keys(jsonStr).forEach(key => {
    if (jsonStr[key] !== undefined) {
      formData.append(key, jsonStr[key]);
    }
  });
  return formData;
}

export function json2Str(json) {
  return JSON.parse(json);
}

export function str2Json(str) {
  return JSON.stringify(str);
}
